import styled from 'styled-components';

export const breakpoints = {
  xs: '(max-width: 639px)',
  sm: '(min-width: 640px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
  xxl: '(min-width: 1536px)',
};

export const DiscoverMoreStyled = styled.div`
  margin-bottom: 4rem;
  gap: 1.5rem;

  .image-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 1rem;

    > h3 {
      position: absolute;
      left: 2rem;
      bottom: 2rem;
      text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
      z-index: 2;
      transition: transform 0.5s ease;
    }

    .image-btn {
      position: absolute;
      left: 2rem;
      bottom: 2rem;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.5s 0.1s ease;
    }

    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      transition: opacity 0.5s ease;
      background: rgba(113, 179, 255, 0.16);
      mix-blend-mode: plus-lighter;
      content: ' ';
      inset: 0;
      z-index: 1;
      opacity: 1;
    }

    &:after {
      background: linear-gradient(180deg, rgba(0, 16, 43, 0) 0%, rgba(0, 16, 43, 0.7) 130%);
      opacity: 1;
      transition: opacity 0.5s ease;
      width: 100%;
      height: 100%;
      position: absolute;
      content: ' ';
      inset: 0;
      z-index: 1;
    }

    &:hover::before {
      opacity: 0;
    }

    &:hover img {
      filter: grayscale(0%);
    }

    &:hover > h3 {
      transform: translateY(-4rem);
    }
    &:hover .image-btn {
      opacity: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: grayscale(100%);
      transition: filter 0.5s ease, mix-blend-mode 0.5s ease;
    }

    @media ${breakpoints.xs} {
      &:hover h3 {
        transform: none;
      }

      &:hover .image-btn {
        transform: none;
      }

      img {
        filter: grayscale(0);
      }
      &::before {
        display: none;
      }
      .image-btn {
        left: auto;
        right: 2rem;
        bottom: 1.3rem;
        opacity: 1;
      }
      > h3 {
        opacity: 1;
        right: 2rem;
        left: auto;
        bottom: 4.4rem;
      }
    }
  }
`;
