export enum CardSectionMode {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export interface CardsSectionProps<T> {
  data: T[];
  loading?: boolean;
  renderCard: (item: T, index: number) => JSX.Element;
  visibleCards: VisibleCardsProps;
  className?: React.ComponentProps<'div'>['className'];
  cardsWrapperClassName?: React.ComponentProps<'div'>['className'];
  scrollOffset?: ScrollOffsetProps;
  prevArrow?: React.ReactElement;
  nextArrow?: React.ReactElement;
  gap: string;
  showArrowsOnMobile?: boolean;
  mode?: CardSectionModeProps;
}
export interface CardProps {
  visibleCards: VisibleCardsProps;
  scrollOffset: ScrollOffsetProps;
  gap: string;
  mode: CardSectionModeProps;
}

export interface CardSectionModeProps {
  base: CardSectionMode;
  xs?: CardSectionMode;
  sm?: CardSectionMode;
  md?: CardSectionMode;
  lg?: CardSectionMode;
  xl?: CardSectionMode;
  xxl?: CardSectionMode;
}

export interface VisibleCardsProps {
  base: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
}

export interface ScrollOffsetProps {
  base: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
}

export interface getDimensionConfigReturnProps {
  numCards: number;
  currentScrollOffset: number;
  currentMode: CardSectionMode;
}
