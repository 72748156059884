import * as React from 'react';

export default function InstagramIcon({
  className,
  viewBox = '0 0 16 16',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox={viewBox || '0 0 20 20'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00006 5.3333C7.29282 5.3333 6.61454 5.61425 6.11445 6.11435C5.61435 6.61445 5.3334 7.29272 5.3334 7.99997C5.3334 8.70721 5.61435 9.38549 6.11445 9.88558C6.61454 10.3857 7.29282 10.6666 8.00006 10.6666C8.70731 10.6666 9.38559 10.3857 9.88568 9.88558C10.3858 9.38549 10.6667 8.70721 10.6667 7.99997C10.6667 7.29272 10.3858 6.61445 9.88568 6.11435C9.38559 5.61425 8.70731 5.3333 8.00006 5.3333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8 0C3.52696 0 2.30606 0.505713 1.40589 1.40589C0.505713 2.30606 0 3.52696 0 4.8V11.2C0 12.473 0.505713 13.6939 1.40589 14.5941C2.30606 15.4943 3.52696 16 4.8 16H11.2C12.473 16 13.6939 15.4943 14.5941 14.5941C15.4943 13.6939 16 12.473 16 11.2V4.8C16 3.52696 15.4943 2.30606 14.5941 1.40589C13.6939 0.505713 12.473 0 11.2 0H4.8ZM4.26667 8C4.26667 7.00986 4.66 6.06027 5.36014 5.36014C6.06027 4.66 7.00986 4.26667 8 4.26667C8.99014 4.26667 9.93973 4.66 10.6399 5.36014C11.34 6.06027 11.7333 7.00986 11.7333 8C11.7333 8.99014 11.34 9.93973 10.6399 10.6399C9.93973 11.34 8.99014 11.7333 8 11.7333C7.00986 11.7333 6.06027 11.34 5.36014 10.6399C4.66 9.93973 4.26667 8.99014 4.26667 8ZM11.7333 4.26667H12.8V3.2H11.7333V4.26667Z"
        fill="currentColor"
      />
    </svg>
  );
}
