import { useEffect, useRef, useState } from 'react';
import classNames from 'styles/utils/classNames';
import { CardSectionMode, CardsSectionProps } from './props';
import { CardWrapper, Container } from './styles';
import { loadingSkeletonGrid } from './utils';

const CardsSection = <T,>({
  data,
  renderCard,
  visibleCards,
  className,
  showArrowsOnMobile = false,
  loading = false,
  scrollOffset = { base: 0 },
  prevArrow,
  nextArrow,
  gap,
  mode = { base: CardSectionMode.HORIZONTAL },
}: CardsSectionProps<T>) => {
  const [showPrevArrow, setShowPrevArrow] = useState<boolean>(false);
  const [showNextArrow, setShowNextArrow] = useState<boolean>(true);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = (direction: 'prev' | 'next') => {
    let cardSize = 0;
    let isHorizontal = true;

    if (containerRef.current?.firstElementChild) {
      const firstCard = containerRef.current.firstElementChild as HTMLElement;

      const containerStyle = getComputedStyle(containerRef.current);
      isHorizontal = containerStyle.flexDirection === 'row';

      cardSize = isHorizontal ? firstCard.offsetWidth : firstCard.offsetHeight;
    }

    if (containerRef.current && cardSize > 0) {
      const scrollAmount = direction === 'next' ? cardSize : -cardSize;

      const scrollOptions: ScrollToOptions = isHorizontal
        ? { left: scrollAmount, behavior: 'smooth' }
        : { top: scrollAmount, behavior: 'smooth' };

      containerRef.current.scrollBy(scrollOptions);
    }
  };

  const updateArrowVisibility = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setShowPrevArrow(scrollLeft > 0);
      setShowNextArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    updateArrowVisibility();

    const handleScrollEvent = () => {
      updateArrowVisibility();
    };

    containerRef.current?.addEventListener('scroll', handleScrollEvent);

    return () => {
      containerRef.current?.removeEventListener('scroll', handleScrollEvent);
    };
  }, [data]);

  return (
    <div className={className}>
      <div className="relative h-full w-full">
        {!loading && prevArrow && showPrevArrow && (
          <span
            className={classNames(
              'absolute left-4 top-1/2 z-10 -translate-y-1/2 cursor-pointer select-none',
              showArrowsOnMobile === false && 'max-sm:hidden',
            )}
            onClick={() => handleScroll('prev')}
          >
            {prevArrow}
          </span>
        )}
        {!loading && nextArrow && showNextArrow && (
          <span
            className={classNames(
              'absolute right-4 top-1/2 z-10 -translate-y-1/2 cursor-pointer select-none',
              showArrowsOnMobile === false && 'max-sm:hidden',
            )}
            onClick={() => handleScroll('next')}
          >
            {nextArrow}
          </span>
        )}
        <Container
          ref={containerRef}
          gap={gap}
          mode={mode}
          visibleCards={visibleCards}
          scrollOffset={scrollOffset}
        >
          {loading &&
            loadingSkeletonGrid.map((Item, index) => (
              <CardWrapper
                key={index}
                visibleCards={visibleCards}
                scrollOffset={scrollOffset}
                mode={mode}
                gap={gap}
              >
                <Item />
              </CardWrapper>
            ))}

          {!loading &&
            data.map((item, index) => (
              <CardWrapper
                key={index}
                visibleCards={visibleCards}
                scrollOffset={scrollOffset}
                mode={mode}
                gap={gap}
              >
                {renderCard(item, index)}
              </CardWrapper>
            ))}
        </Container>
      </div>
    </div>
  );
};

export default CardsSection;
