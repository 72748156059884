import * as React from 'react';

export default function Net({ className, viewBox }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox={viewBox || '0 0 20 20'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99199 1.66797C5.39199 1.66797 1.66699 5.4013 1.66699 10.0013C1.66699 14.6013 5.39199 18.3346 9.99199 18.3346C14.6003 18.3346 18.3337 14.6013 18.3337 10.0013C18.3337 5.4013 14.6003 1.66797 9.99199 1.66797ZM12.6837 14.493L10.0003 12.8763L7.31699 14.493C7.00033 14.6846 6.60866 14.4013 6.69199 14.043L7.40033 10.993L5.04199 8.9513C4.76699 8.70964 4.91699 8.2513 5.28366 8.21797L8.40033 7.9513L9.61699 5.0763C9.75866 4.73464 10.242 4.73464 10.3837 5.0763L11.6003 7.94297L14.717 8.20964C15.0837 8.24297 15.2337 8.7013 14.9503 8.94297L12.592 10.9846L13.3003 14.043C13.3837 14.4013 13.0003 14.6846 12.6837 14.493Z"
        fill="currentColor"
      />
    </svg>
  );
}
