import { memo, useState } from 'react';
import { RemoteCoachTypesEnum } from 'utils/shared/coach/constants';
import { useModal } from 'hooks/useModal';
import LessonCard from 'screens/PlayerHome/LessonCard';
import { LessonBookingTabEnum, OnCourtMobileProps } from 'screens/PlayerHome/props';
import CardsSection from 'components/CardSection';
import classNames from 'styles/utils/classNames';
import BookLessonModal from './BookLessonModal';
import LocationPicker from './LocationPicker';

const LessonCardMobile = memo(LessonCard.Mobile, (prevProps, nextProps) => {
  return prevProps.isActive === nextProps.isActive && prevProps.lesson.id === nextProps.lesson.id;
});

const OnCourtMobile: React.FC<OnCourtMobileProps> = ({
  onCourtLessons,
  lessonsLoading,
  setActiveLesson,
  venues,
  setActiveLocation,
  activeLocation,
  activeOwnerLessons,
  coachAvailability,
  handleSelectSlot,
  selectedDate,
  handleBookLesson,
  nearbyVenues,
  venuesLoading,
  activeTab,
}) => {
  const [activeLessonIndex, setActiveLessonIndex] = useState<number>(0);

  const bookLessonModal = useModal();

  const handleCardClick = (index: number) => {
    setActiveLesson(onCourtLessons[index]);
    setActiveLessonIndex(index);
  };

  return (
    <div className={classNames('hidden', activeTab === LessonBookingTabEnum.OnCourt && '!block')}>
      <CardsSection
        gap="1rem"
        loading={lessonsLoading}
        className="max-sm:pl-4"
        data={onCourtLessons}
        visibleCards={{ base: 2, md: 4, xl: 5 }}
        scrollOffset={{ base: 0, xs: 30 }}
        renderCard={(item, index) => (
          <LessonCardMobile
            key={item.id}
            isActive={index === activeLessonIndex}
            onClick={() => handleCardClick(index)}
            lesson={item}
            remoteCoachType={
              activeTab === LessonBookingTabEnum.OnCourt
                ? RemoteCoachTypesEnum.Court
                : RemoteCoachTypesEnum.Remote
            }
          />
        )}
      />
      <LocationPicker
        handlePickTime={bookLessonModal.openModal}
        // @ts-ignore Need to allow for either
        venues={venues?.length ? venues : nearbyVenues}
        setActiveLocation={setActiveLocation}
        activeLocation={activeLocation}
        nearbyVenues={nearbyVenues}
        loading={venuesLoading}
      />
      <BookLessonModal
        activeOwnerLessons={activeOwnerLessons}
        coachAvailability={coachAvailability}
        isOpen={bookLessonModal.isOpen}
        handleClose={bookLessonModal.closeModal}
        activeLocation={activeLocation}
        handleSelectSlot={handleSelectSlot}
        selectedDate={selectedDate}
        handleBookLesson={handleBookLesson}
      />
    </div>
  );
};

export default OnCourtMobile;
