import LoadingSkeleton from 'components/LoadingSkeleton';
import { VisibleCardsProps } from './props';

export const breakpoints = {
  xs: '(max-width: 639px)',
  sm: '(min-width: 640px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
  xxl: '(min-width: 1536px)',
};

export const CardLoadingSkeleton = () => {
  return (
    <div className="h-full w-full flex-shrink-0 overflow-hidden rounded-lg ">
      <LoadingSkeleton height="11.25rem" />
      <div className="mt-2.5">
        <LoadingSkeleton count={3} />
      </div>
    </div>
  );
};

export const loadingSkeletonGrid = [
  CardLoadingSkeleton,
  CardLoadingSkeleton,
  CardLoadingSkeleton,
  CardLoadingSkeleton,
  CardLoadingSkeleton,
  CardLoadingSkeleton,
  CardLoadingSkeleton,
  CardLoadingSkeleton,
  CardLoadingSkeleton,
];

export const getCardSize = (
  visibleCards: number,
  scrollOffset: number,
  containerSize: string,
  gap: string,
) => `
  calc((${containerSize} - ((${visibleCards} - 1) * ${gap}) - ${scrollOffset}px) / ${visibleCards})
`;

export const getResponsiveValue = <T extends unknown>(
  prop: { base: T; xs?: T; sm?: T; md?: T; lg?: T; xl?: T; xxl?: T },
  breakpoint: keyof VisibleCardsProps, // The key we are trying to get
): T => {
  const breakpointsOrder: Array<keyof VisibleCardsProps> = [
    'base',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    'xxl',
  ];

  if (prop[breakpoint] !== undefined) {
    return prop[breakpoint] as T;
  }

  const currentBreakpointIndex = breakpointsOrder.indexOf(breakpoint);

  for (let i = currentBreakpointIndex - 1; i >= 0; i--) {
    const currentBreakpoint = breakpointsOrder[i];
    if (prop[currentBreakpoint] !== undefined) {
      return prop[currentBreakpoint] as T;
    }
  }

  return prop.base;
};
