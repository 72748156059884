import * as React from 'react';

export default function Price({ className }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 10" className={className}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="currentColor"
        d="M2.2001 0.199951C1.77575 0.199951 1.36878 0.368522 1.06873 0.66858C0.768668 0.968638 0.600098 1.3756 0.600098 1.79995V4.99995C0.600098 5.4243 0.768668 5.83126 1.06873 6.13132C1.36878 6.43138 1.77575 6.59995 2.2001 6.59995V1.79995H10.2001C10.2001 1.3756 10.0315 0.968638 9.73145 0.66858C9.4314 0.368522 9.02443 0.199951 8.60008 0.199951H2.2001ZM3.80009 4.99995C3.80009 4.5756 3.96866 4.16864 4.26872 3.86858C4.56878 3.56852 4.97574 3.39995 5.40009 3.39995H11.8001C12.2244 3.39995 12.6314 3.56852 12.9314 3.86858C13.2315 4.16864 13.4001 4.5756 13.4001 4.99995V8.19995C13.4001 8.6243 13.2315 9.03126 12.9314 9.33132C12.6314 9.63138 12.2244 9.79995 11.8001 9.79995H5.40009C4.97574 9.79995 4.56878 9.63138 4.26872 9.33132C3.96866 9.03126 3.80009 8.6243 3.80009 8.19995V4.99995ZM8.60008 8.19995C9.02443 8.19995 9.4314 8.03138 9.73145 7.73132C10.0315 7.43126 10.2001 7.0243 10.2001 6.59995C10.2001 6.1756 10.0315 5.76864 9.73145 5.46858C9.4314 5.16852 9.02443 4.99995 8.60008 4.99995C8.17574 4.99995 7.76877 5.16852 7.46872 5.46858C7.16866 5.76864 7.00009 6.1756 7.00009 6.59995C7.00009 7.0243 7.16866 7.43126 7.46872 7.73132C7.76877 8.03138 8.17574 8.19995 8.60008 8.19995Z"
      />
    </svg>
  );
}
