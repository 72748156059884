import { useState } from 'react';
import { UploadProvider } from 'context/UploadVideoContext';
import CreateAccount from 'screens/CoachDetails/LessonScheduler/CreateAccount';
import Login from 'screens/CoachDetails/LessonScheduler/Login';
import PaymentForm from 'screens/CoachDetails/LessonScheduler/PaymentForm';
import ResultForm from 'screens/CoachDetails/LessonScheduler/ResultForm';
import VideoAnalysisSubmission from 'screens/CoachDetails/RemoteLessonScheduler/VideoAnalysisSubmission';
import {
  Coach,
  Steps as ConfirmLessonSteps,
  VideoAnalysisSubmissionType,
} from 'screens/CoachDetails/types';
import { RemoteAnalysisModalProps } from 'screens/PlayerHome/props';
import Modal from 'components/modals/Modal';

const RemoteAnalysisModal: React.FC<RemoteAnalysisModalProps> = ({
  open,
  handleClose,
  activeLesson,
}) => {
  const [steps, setSteps] = useState<ConfirmLessonSteps>(ConfirmLessonSteps.VideoUploader);

  const [submissionData, setSubmissionData] = useState<VideoAnalysisSubmissionType>();

  const [bookedLessonId, setBookedLessonId] = useState<string | null>(null);

  const coach = activeLesson as never as Coach;

  const handleCloseModal = () => {
    handleClose();
    setTimeout(() => {
      setSteps(ConfirmLessonSteps.VideoUploader);
    }, 300); // to align with modal closing animation
  };

  return (
    <Modal isOpen={open} handleClose={handleCloseModal}>
      {steps === ConfirmLessonSteps.CreateAccount && (
        <CreateAccount
          setSteps={setSteps}
          handleNext={() => {
            setSteps(ConfirmLessonSteps.VideoUploader);
          }}
        />
      )}
      {steps === ConfirmLessonSteps.Login && (
        <Login
          setSteps={setSteps}
          handleNext={() => {
            setSteps(ConfirmLessonSteps.VideoUploader);
          }}
        />
      )}

      {steps === ConfirmLessonSteps.VideoUploader && (
        <UploadProvider>
          <VideoAnalysisSubmission
            coach={coach}
            setSteps={setSteps}
            setSubmissionData={setSubmissionData}
            handleClose={handleCloseModal}
          />
        </UploadProvider>
      )}

      {steps === ConfirmLessonSteps.PaymentForm && (
        <div className="pt-6">
          <PaymentForm
            setBookedLessonId={setBookedLessonId}
            setSteps={setSteps}
            coach={coach}
            isRemoteCoaching={true}
            remoteCoachData={submissionData}
            handleClose={handleCloseModal}
          />
        </div>
      )}
      {steps === ConfirmLessonSteps.ResultForm && (
        <div className="py-5">
          <ResultForm
            bookedLessonId={bookedLessonId}
            setSteps={setSteps}
            coach={coach}
            isRemoteCoaching={true}
          />
        </div>
      )}
    </Modal>
  );
};

export default RemoteAnalysisModal;
