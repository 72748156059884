import * as React from 'react';
import {
  COMPANY_FACEBOOK_PAGE,
  COMPANY_INSTAGRAM,
  COMPANY_LINKEDIN,
  DISCORD_LINK,
} from 'constants/internal';
import {
  ABOUT_PAGE,
  BLOG_PAGE,
  CITY_AUSTIN,
  CITY_CHICAGO,
  CITY_LASVEGAS,
  CITY_NEWYORK,
  CITY_SEATTLE,
  COUNTRY_USA_PAGE,
  COURT_FINDER_PAGE,
  LESSONS_PAGE,
  PRIVACY_PAGE,
  REMOTE_COACHES_PAGE,
  ROOT_PAGE,
  TOURNAMENTS_PAGE,
  TOURNAMENT_CREATE_PAGE,
  TOURNAMENT_QUICK_CREATE_PAGE,
} from 'constants/pages';
import { useViewer } from 'hooks/useViewer';
import FaceBookIcon from 'svg/FaceBookIcon';
import InstagramIcon from 'svg/InstagramIcon';
import LinkedInIcon from 'svg/LinkedInIcon';
import LogoNav from 'svg/LogoNav';
import MoonIcon from 'svg/MoonIcon';
import SunIcon from 'svg/SunIcon';
import ContactModal from 'screens/TournamentMarketPlace/ContactForm/ContactModal';
import BottomRegisterBar from 'components/BottomRegisterBar';
import { ButtonLink, ButtonText } from 'components/Button/Button';
import Link from 'components/Link';

interface Props {
  isBottomRegister?: boolean;
  onSubmitSignup?: () => void;
  ignoreText?: string;
  ignoreAction?: () => void;
  isBottomBarRegisterMobile?: boolean;
}
interface FooterLinkProps {
  items: Items;
}
interface Items {
  sections: Section[];
}

interface ItemObject {
  title: string;
  href: string;
}
interface Section {
  title: string;
  footerItems: ItemObject[];
}

const items = {
  sections: [
    {
      title: 'Product',
      footerItems: [
        { title: 'Remote lessons', href: REMOTE_COACHES_PAGE },
        { title: 'On-court lessons', href: LESSONS_PAGE },
        { title: 'Courts', href: COURT_FINDER_PAGE },
        { title: 'Tournaments', href: TOURNAMENTS_PAGE },
      ],
    },
    // {
    //   title: 'Blog',
    //   footerItems: [
    //     { title: 'Staying in the Game', href: BLOG_INJURIES },
    //     { title: 'Find Pickleball Coach', href: FIND_COACH },
    //     { title: 'Bounce rolls out with Gamma', href: BOUNCE_ROLL_OUT },
    //   ],
    // },
    {
      title: 'Cities',
      footerItems: [
        { title: 'Austin', href: CITY_AUSTIN },
        { title: 'Chicago', href: CITY_CHICAGO },
        { title: 'New York', href: CITY_NEWYORK },
        { title: 'Las Vegas', href: CITY_LASVEGAS },
        { title: 'Seattle', href: CITY_SEATTLE },
        { title: 'Pickleball in the USA', href: COUNTRY_USA_PAGE },
      ],
    },
    {
      title: 'Company',
      footerItems: [
        { title: 'Blog', href: BLOG_PAGE },
        // { title: 'About', href: ABOUT_PAGE },
        { title: 'Privacy Policy', href: PRIVACY_PAGE },
        // { title: 'Bounce Is Coming Back', href: '#' },
      ],
    },
  ],
};

const FooterLink = ({ items }: FooterLinkProps) => (
  <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
    {items.sections &&
      items.sections.map((section, sectionIndex) => (
        <div className="flex flex-col gap-4 " key={sectionIndex}>
          {/* Display section title */}
          {/**
           * @note since this section is black in both light and dark mode, we only need one font color
           */}
          <p className="typography-product-element-label text-color-text-lightmode-invert">
            {section.title}
          </p>
          {/* Map through items */}
          {section.footerItems.map((item, itemIndex) => (
            <div key={itemIndex}>
              {/* Check if link is defined before rendering */}
              {section.footerItems && section.footerItems[itemIndex] && (
                <Link
                  href={section.footerItems[itemIndex].href}
                  key={itemIndex}
                  className="typography-product-caption justify-start text-left text-color-text-darkmode-placeholder"
                >
                  {item.title}
                </Link>
              )}
            </div>
          ))}
        </div>
      ))}
  </div>
);

export default function Footer({
  isBottomRegister,
  onSubmitSignup,
  ignoreText,
  ignoreAction,
  isBottomBarRegisterMobile,
}: Props) {
  const { isSessionLoading, isUserSession } = useViewer();

  return (
    <>
      <footer className="w-full items-center border-t border-color-border-input-darkmode bg-color-bg-darkmode-primary px-4 py-8  sm:px-8">
        <div className="mx-auto flex flex-col space-y-8 md:flex-row md:justify-between md:space-y-0">
          <div className="flex flex-col gap-12">
            <div className="flex flex-col">
              <Link href={ROOT_PAGE} className="" aria-label="Home">
                <LogoNav className="h-10 text-color-text-darkmode-primary dark:text-color-text-lightmode-primary" />
              </Link>
              {/* <div className="hidden md:flex md:justify-start">
              <span className="mr-4">
                <Button className="w-1/2" variant="primary">
                  <MoonIcon className="h-6 w-6 text-white" />
                </Button>
              </span>
              <span>
                <Button className="w-1/2" variant="primary">
                  <SunIcon className="h-6 w-6" />
                </Button>
              </span>
            </div> */}
            </div>
            <div className="flex flex-col gap-3">
              <h6 className="typography-product-body-highlight flex-wrap break-words text-color-text-lightmode-invert dark:text-color-text-darkmode-invert xs:w-full md:w-48">
                Bounce is the #1 app for racquet sports
              </h6>
              <p className="typography-product-caption break-words text-color-text-darkmode-placeholder dark:text-color-text-lightmode-placeholder xs:w-full md:w-60">
                Lessons, tournaments, open play, communities, and courts - Bounce handles it on a
                single platform, built specifically for you.
              </p>
            </div>
          </div>
          <div className="flex flex-row gap-20">
            <FooterLink items={items} />
          </div>
          <div className="pb-6 xs:pt-10 md:py-0">
            <ButtonLink
              href={REMOTE_COACHES_PAGE}
              size="sm"
              sizeDesktop="md"
              sizeMobile="lg"
              variant="primary"
              className="border-white"
            >
              Find a coach
            </ButtonLink>
          </div>
          {/* <div className="flex md:hidden">
            <div>
              <Button variant="primary" size="xs">
                <MoonIcon className="h-6 w-6 text-white" />
              </Button>
            </div>
            <div>
              <Button variant="primary" size="xs">
                <SunIcon className="h-6 w-6" />
              </Button>
            </div>
          </div> */}
        </div>
        <div className="mx-auto mt-8 flex flex-col-reverse gap-5 space-y-8 border-t border-color-border-input-darkmode pt-8 dark:border-color-border-input-lightmode md:flex-row md:justify-between md:space-y-0">
          <div className="w-full text-base font-normal text-color-text-darkmode-tertiary dark:text-color-text-lightmode-tertiary">
            © {new Date().getFullYear()} Bounce. All rights reserved.
          </div>
          <div className="xs: flex w-full justify-between md:items-center md:justify-end">
            <div className="mr-8">
              <Link
                isExternal
                href="mailto:team@bounce.game"
                className="text-base text-color-text-darkmode-primary dark:text-color-text-lightmode-primary"
              >
                team@bounce.game
              </Link>
            </div>
            <div className="flex shrink-0 justify-between text-color-text-darkmode-primary dark:text-color-text-lightmode-primary xs:mb-8 xs:space-x-12 md:mb-0 md:items-center md:space-x-6">
              <a
                className="shrink-0 text-color-text-darkmode-primary dark:text-color-text-lightmode-primary"
                href={COMPANY_INSTAGRAM}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <InstagramIcon className="h-6 w-6" />
              </a>
              <a
                className="shrink-0 text-color-text-darkmode-primary dark:text-color-text-lightmode-primary"
                href={COMPANY_FACEBOOK_PAGE}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <FaceBookIcon className="h-6 w-6" />
              </a>
              <a
                className="shrink-0 text-color-text-darkmode-primary dark:text-color-text-lightmode-primary"
                href={COMPANY_LINKEDIN}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <LinkedInIcon className="h-6 w-6" />
              </a>
            </div>
          </div>
        </div>
      </footer>
      {isBottomRegister && !isSessionLoading && !isUserSession && (
        <BottomRegisterBar
          isShowMobile={isBottomBarRegisterMobile}
          onSubmitSignup={onSubmitSignup}
          ignoreText={ignoreText}
          ignoreAction={ignoreAction}
        />
      )}
    </>
  );
}
