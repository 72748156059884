import { memo, useEffect, useState } from 'react';
import { RemoteCoachTypesEnum } from 'utils/shared/coach/constants';
import { useModal } from 'hooks/useModal';
import AddPhotoV2 from 'svg/AddPhotoV2';
import LessonCard from 'screens/PlayerHome/LessonCard';
import {
  CombinedCoachType,
  LessonBookingTabEnum,
  RemoteMobileProps,
} from 'screens/PlayerHome/props';
import Button from 'components/Button';
import CardsSection from 'components/CardSection';
import classNames from 'styles/utils/classNames';
import RemoteAnalysisModal from './RemoteAnalysisModal';

const LessonCardMobile = memo(LessonCard.Mobile, (prevProps, nextProps) => {
  return (
    prevProps.isActive === nextProps.isActive &&
    prevProps.lesson.id === nextProps.lesson.id &&
    prevProps.remoteCoachType === nextProps.remoteCoachType
  );
});

const RemoteMobile: React.FC<RemoteMobileProps> = ({
  remoteLessons,
  lessonsLoading,
  activeTab,
}) => {
  const [remoteActiveLesson, setRemoteActiveLesson] = useState<CombinedCoachType | null>(null);

  const remoteAnalysisModal = useModal();

  const handleCardClick = (index: number) => {
    setRemoteActiveLesson(remoteLessons[index]);
  };

  useEffect(() => {
    setRemoteActiveLesson(remoteLessons[0]);
  }, [remoteLessons]);

  return (
    <>
      <div className={classNames('hidden', activeTab === LessonBookingTabEnum.Remote && '!block')}>
        <CardsSection
          gap="1rem"
          loading={lessonsLoading}
          className="max-sm:pl-4"
          data={remoteLessons}
          visibleCards={{ base: 2, md: 4, xl: 5 }}
          scrollOffset={{ base: 0, xs: 30 }}
          renderCard={(item, index) => (
            <LessonCardMobile
              key={item.id}
              isActive={item.id === remoteActiveLesson?.id}
              onClick={() => handleCardClick(index)}
              remoteCoachType={
                activeTab === LessonBookingTabEnum.OnCourt
                  ? RemoteCoachTypesEnum.Court
                  : RemoteCoachTypesEnum.Remote
              }
              lesson={item}
            />
          )}
        />
        <div className="px-4">
          <Button
            iconLeft={<AddPhotoV2 viewBox="0 2 32 32" className="h-4 w-4 text-white" />}
            variant="brand"
            sizeMobile="md"
            className="mt-8"
            onClick={() => remoteAnalysisModal.openModal()}
          >
            Add Video
          </Button>
        </div>
      </div>
      <RemoteAnalysisModal
        open={remoteAnalysisModal.isOpen}
        handleClose={remoteAnalysisModal.closeModal}
        activeLesson={remoteActiveLesson}
      />
    </>
  );
};

export default RemoteMobile;
