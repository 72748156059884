// @ts-nocheck REMOVE THIS ASAP. Vercel was throwing an error in this file, but I can't reproduce it locally.
import CardsSection from 'components/CardSection';
import { CardSectionMode } from 'components/CardSection/props';
import LocationCard from '../LocationCard';
import { ChooseLocationStepProps } from '../props';

const ChooseLocationStep: React.FC<ChooseLocationStepProps> = ({
  venues,
  venuesLoading,
  activeLocation,
  setActiveLocation,
  nearbyVenues,
}) => {
  return (
    <div className="min-w-0 flex-1">
      <span className="typography-product-subheading mb-5 inline-block xl:hidden">Location</span>
      <CardsSection
        className="xl:h-[35rem]"
        gap="1rem"
        loading={venuesLoading}
        visibleCards={{ base: 1, md: 2, lg: 3, xl: 5 }}
        scrollOffset={{ base: 60 }}
        mode={{
          base: CardSectionMode.HORIZONTAL,
          lg: CardSectionMode.HORIZONTAL,
          xl: CardSectionMode.VERTICAL,
          xxl: CardSectionMode.VERTICAL,
        }}
        // @ts-ignore Needs fixed
        data={venues?.length ? venues : nearbyVenues}
        renderCard={(item) => (
          <LocationCard
            activeLocation={activeLocation}
            setActiveLocation={setActiveLocation}
            venue={item}
          />
        )}
      />
    </div>
  );
};

export default ChooseLocationStep;
