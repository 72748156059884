import { BookingSwitcherProps, LessonBookingTabEnum } from './props';

const BookingSwitcher: React.FC<BookingSwitcherProps> = ({ activeTab, setActiveTab }) => {
  return (
    <div className="relative mx-auto flex h-10 flex-col items-center justify-center whitespace-nowrap rounded-3xl bg-color-bg-lightmode-secondary p-0.5 py-1 dark:bg-color-bg-darkmode-secondary  xs:w-full md:w-80">
      <div className="relative flex w-full flex-1 justify-center">
        <div
          className={`absolute bottom-0 left-0 top-0 w-1/2 rounded-3xl transition-transform duration-300 ${
            activeTab === LessonBookingTabEnum.Remote ? 'translate-x-0' : 'translate-x-full'
          } bg-color-bg-lightmode-brand dark:bg-color-bg-darkmode-brand`}
        ></div>
        <button
          onClick={() => setActiveTab(LessonBookingTabEnum.Remote)}
          className={`z-10 flex-1 shrink self-stretch overflow-hidden rounded-3xl px-6 ${
            activeTab === LessonBookingTabEnum.Remote
              ? 'text-color-text-lightmode-invert dark:text-color-text-darkmode-invert'
              : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary'
          }`}
        >
          Remote
        </button>
        <button
          onClick={() => setActiveTab(LessonBookingTabEnum.OnCourt)}
          className={`z-10 flex-1 shrink self-stretch overflow-hidden rounded-3xl px-6 ${
            activeTab === LessonBookingTabEnum.OnCourt
              ? 'text-color-text-lightmode-invert dark:text-color-text-darkmode-invert'
              : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary'
          }`}
        >
          On-court
        </button>
      </div>
    </div>
  );
};

export default BookingSwitcher;
