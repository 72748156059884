import { VenueAccessTypesEnum, VenueNetsEnum } from 'types/generated/client';
import CourtFlat from 'svg/CourtFlat';
import Location from 'svg/Location';
import Membership from 'svg/Membership';
import Net from 'svg/Net';
import Public from 'svg/Public';
import Users from 'svg/Users';
import { LocationCardProps } from '../props';

const getAccessParamters = (accessType?: VenueAccessTypesEnum | null) => {
  if (!accessType || accessType === VenueAccessTypesEnum.NeedsAudit) {
    return null;
  }

  if (
    accessType === VenueAccessTypesEnum.Membership ||
    accessType === VenueAccessTypesEnum.Private
  ) {
    return {
      name: 'Membership',
      Icon: Lock,
    };
  } else if (accessType === VenueAccessTypesEnum.OneTime) {
    return {
      name: 'One-time fee',
      Icon: Membership,
    };
  } else {
    return {
      name: 'Free',
      Icon: Public,
    };
  }
};

const LocationCard: React.FC<LocationCardProps> = ({
  venue,
  activeLocation,
  setActiveLocation,
}) => {
  const getTextWithIcon = (
    Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>,
    text: string | number,
  ) => (
    <div className="inline-flex min-w-0 gap-1">
      {<Icon className="h-4 w-4 text-color-text-lightmode-tertiary" />}
      <span className="typography-product-text-card-desktop truncate  text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
        {text}
      </span>
    </div>
  );

  const totalCourtCount = (venue.indoorCourtCount || 0) + (venue.outdoorCourtCount || 0);
  // @ts-ignore Need to reconcile with the nearby courts passed when it's a coach_starter_account
  const addressText = venue.address || venue.addressString;

  return (
    <label className="flex cursor-pointer gap-2 rounded-lg border border-color-border-card-lightmode px-4 py-3 dark:border-color-border-card-darkmode">
      <div className="relative h-5 w-5 shrink-0 rounded-full border bg-color-bg-lightmode-primary dark:bg-color-bg-darkmode-primary">
        <input
          type="radio"
          name="location-option"
          id="location-option"
          className="peer hidden"
          checked={!!activeLocation && activeLocation.id === venue.id}
          onChange={(e) => e.target.checked && setActiveLocation(venue)}
        />
        <div className="absolute inset-1 rounded-full peer-checked:bg-color-brand-primary" />
      </div>
      <div className="flex min-w-0 flex-col gap-2">
        {!!addressText && getTextWithIcon(Location, addressText)}
        {venue.title && (
          <span className="typography-product-card-title-desktop truncate">{venue.title}</span>
        )}
        <div className="flex gap-4">
          {venue.accessType &&
            !!getAccessParamters(venue.accessType)?.name &&
            getTextWithIcon(Users, getAccessParamters(venue.accessType)?.name!)}
          {totalCourtCount > 0 && getTextWithIcon(CourtFlat, `${totalCourtCount} Courts`)}
          {venue.pickleballNets &&
            getTextWithIcon(
              Net,
              venue.pickleballNets === VenueNetsEnum.Permanent ||
                venue.pickleballNets === VenueNetsEnum.Tennis
                ? 'Permanent'
                : 'Temp',
            )}
        </div>
      </div>
    </label>
  );
};

export default LocationCard;
