import * as React from 'react';

export default function ArrowRight({
  className,
  viewBox = '0 0 16 16',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={className} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83441 4.23431C10.1468 3.9219 10.6534 3.9219 10.9658 4.23431L14.1658 7.43431C14.4782 7.74673 14.4782 8.25327 14.1658 8.56569L10.9658 11.7657C10.6534 12.0781 10.1468 12.0781 9.83441 11.7657C9.52199 11.4533 9.52199 10.9467 9.83441 10.6343L11.6687 8.8H2.4001C1.95827 8.8 1.6001 8.44183 1.6001 8C1.6001 7.55817 1.95827 7.2 2.4001 7.2H11.6687L9.83441 5.36569C9.52199 5.05327 9.52199 4.54673 9.83441 4.23431Z"
        fill="currentColor"
      />
    </svg>
  );
}
