import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import CheckIcon from 'svg/CheckIcon';
import ChevronDown from 'svg/ChevronDown';
import LoadSpinner from 'svg/LoadSpinner';
import { LocationPickerProps } from 'screens/PlayerHome/props';
import Button from 'components/Button';
import classNames from 'styles/utils/classNames';

const LocationPicker: React.FC<LocationPickerProps> = ({
  handlePickTime,
  setActiveLocation,
  activeLocation,
  venues,
  nearbyVenues,
  loading,
}) => {
  return (
    <div className="mt-7 px-4">
      <div className="relative h-9">
        <Listbox
          value={activeLocation?.id}
          disabled={!venues.length || loading}
          onChange={(val) => setActiveLocation(venues.find((venue) => venue.id === val) || null)}
        >
          {({ open }) => (
            <>
              <div className="relative">
                <Listbox.Button
                  className={
                    'input-form relative flex min-h-10 w-full items-center justify-between text-left'
                  }
                >
                  <span
                    className={
                      'typography-product-caption block text-color-text-lightmode-primary dark:text-color-text-darkmode-primary'
                    }
                  >
                    {activeLocation?.title ||
                      activeLocation?.address ||
                      (venues.length === 0 && !loading ? 'No Location' : 'All Locations')}
                  </span>
                  {loading ? (
                    <LoadSpinner className="h-5 w-5 animate-spin" />
                  ) : (
                    <ChevronDown
                      className={classNames(
                        'h-5 w-5 transition-transform duration-300',
                        open && 'rotate-180',
                      )}
                    />
                  )}
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-color-bg-lightmode-primary py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-color-bg-darkmode-primary sm:text-sm">
                    {(venues || nearbyVenues).map((venue) => (
                      <Listbox.Option
                        key={venue.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'bg-color-bg-lightmode-invert text-color-text-lightmode-invert'
                              : 'text-gray-900',
                            'relative cursor-pointer select-none px-4 py-2',
                          )
                        }
                        value={venue.id}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={'block pr-2'}>{venue.title || venue.address}</span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active
                                    ? 'text-color-text-lightmode-invert'
                                    : 'text-color-text-darkmode-invert',
                                  'absolute inset-y-0 right-0 flex items-center pr-1',
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
      <Button
        variant="brand"
        sizeMobile="md"
        className="mt-6"
        disabled={!activeLocation}
        onClick={handlePickTime}
      >
        Pick a Time
      </Button>
    </div>
  );
};

export default LocationPicker;
