import React from 'react';
import Marquee from 'react-fast-marquee';

const MetricSection: React.FC = () => {
  return (
    <Marquee speed={50} gradient={false} className="relative px-4 max-sm:mb-12 sm:mb-20">
      {[1, 2, 3, 4, 5, 6].map((imgIndex) => (
        <img
          key={imgIndex}
          src={`/images/players/metric-image-${imgIndex}.png`}
          alt={`metric ${imgIndex}`}
          className="h-72"
        />
      ))}
    </Marquee>
  );
};

export default MetricSection;
