import Link from 'next/link';
import { Button } from 'components/Button';
import classNames from 'styles/utils/classNames';
import { discoverImages } from './data';
import { DiscoverMoreStyled } from './styles';

const DiscoverMoreSection: React.FC = () => {
  return (
    <section className="mx-auto sm:container max-sm:px-4">
      <h2 className="max-sm:typography-informative-subheading typography-informative-display-secondary-mobile mb-8 max-sm:text-center">
        <mark className="max-sm:typography-informative-subheading-highlight typography-informative-display-primary-mobile bg-transparent">
          Discover
        </mark>{' '}
        more
      </h2>
      <DiscoverMoreStyled className="grid grid-cols-3 grid-rows-2 max-lg:grid-cols-2 max-lg:grid-rows-3 max-sm:grid-cols-1 max-sm:grid-rows-5">
        {discoverImages.map(({ path, title, buttonProps }, index) => (
          <Link
            className={classNames(
              'image-wrapper block max-sm:h-56',
              index === 0 && 'sm:row-span-2',
            )}
            key={index}
            href={buttonProps.href}
          >
            <img
              loading="lazy"
              className="max-sm:grayscale-0"
              src={path}
              alt={`discover-img-${index}`}
            />
            <h3 className="typography-product-heading text-white">{title}</h3>
            <Button
              variant="brand"
              sizeDesktop="lg"
              sizeMobile="md"
              className="image-btn w-fit"
              iconRight={buttonProps.icon}
              onClick={() => {}}
            >
              {buttonProps.title}
            </Button>
          </Link>
        ))}
      </DiscoverMoreStyled>
    </section>
  );
};

export default DiscoverMoreSection;
