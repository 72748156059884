import LessonScheduler from 'screens/CoachDetails/LessonScheduler';
import Modal from 'components/modals/Modal';
import { PaymentModalProps } from './props';
import { getLessonSchedularProps } from './utils';

const PaymentModal: React.FC<PaymentModalProps> = ({
  isOpen,
  handleClose,
  selectedDate,
  activeLocation,
  activeLesson,
  handleBookAnotherLesson,
  onPaymentSuccess,
}) => {
  if (!selectedDate || !activeLocation || !activeLesson) return;

  const lessonSchedularProps = getLessonSchedularProps(
    selectedDate,
    activeLocation,
    activeLesson,
    handleBookAnotherLesson,
    onPaymentSuccess,
  );

  return (
    <Modal
      positionBottomDesktop
      isOpen={isOpen}
      handleClose={handleClose}
      swipeProps={{
        onSwipedDown: handleClose,
        delta: 50,
        trackMouse: true,
        trackTouch: true,
      }}
    >
      <LessonScheduler {...lessonSchedularProps} />
    </Modal>
  );
};

export default PaymentModal;
