import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { VideoAnalysisSkillsTypeEnum } from 'utils/shared/coachBuilder';
import { UploadProvider } from 'context/UploadVideoContext';
import {
  CombinedCoachType,
  LessonAnalyisFormType,
  LessonBookingTabEnum,
  RemoteDesktopProps,
} from 'screens/PlayerHome/props';
import {
  lessonAnalysisFormDefaultValues,
  lessonAnalysisFormSchema,
} from 'screens/PlayerHome/utils';
import Stepper from 'screens/TournamentsBuilder/components/Stepper';
import classNames from 'styles/utils/classNames';
import ConfirmLessonStep from './ConfirmLessonStep';
import UploadVideoStep from './UploadVideoStep';
import SelectCoachStep from '../SelectCoachStep';

const RemoteDesktop: React.FC<RemoteDesktopProps> = ({
  activeTab,
  lessonsLoading,
  remoteLessons,
  openLoginModal,
  onlyFollowedCoached = false,
  setActiveTab,
}) => {
  const [activeStep, setActiveStep] = useState(1);

  const [remoteActiveLesson, setRemoteActiveLesson] = useState<CombinedCoachType | null>(null);

  const lessonAnalysisForm = useForm<LessonAnalyisFormType>({
    resolver: zodResolver(lessonAnalysisFormSchema),
    defaultValues: lessonAnalysisFormDefaultValues,
  });

  const [videoAnalysisFiles, skills] = lessonAnalysisForm.watch(['videoAnalysisFiles', 'skills']);

  const clearForm = () => {
    lessonAnalysisForm.reset(lessonAnalysisFormDefaultValues);
  };

  useEffect(() => {
    const isLessonFormValid = videoAnalysisFiles?.length > 0;
    setActiveStep(isLessonFormValid ? 2 : 1);
  }, [videoAnalysisFiles, skills]);

  useEffect(() => {
    setRemoteActiveLesson(remoteLessons[0]);
  }, [remoteLessons]);

  return (
    <div className={classNames('hidden', activeTab === LessonBookingTabEnum.Remote && '!block')}>
      <div className="flex justify-center">
        <Stepper
          steps={[
            { title: 'Select a coach', description: '' },
            { title: 'Upload your video', description: '' },
            { title: 'Confirm your lesson', description: '' },
          ]}
          currentStep={activeStep}
          className="max-w-full"
        />
      </div>
      <div className="mt-7 flex flex-wrap gap-8">
        <SelectCoachStep
          loading={lessonsLoading}
          lessons={remoteLessons}
          handleLessonSelect={(lesson) => setRemoteActiveLesson(lesson)}
          activeLesson={remoteActiveLesson}
          onlyFollowedCoached={onlyFollowedCoached}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <UploadProvider>
          <UploadVideoStep
            openLoginModal={openLoginModal}
            lessonAnalysisForm={lessonAnalysisForm}
          />
        </UploadProvider>
        <ConfirmLessonStep
          activeLesson={remoteActiveLesson}
          disableSubmit={activeStep < 2}
          submissionData={lessonAnalysisForm.getValues()}
          clearForm={clearForm}
        />
      </div>
    </div>
  );
};

export default RemoteDesktop;
