import { LESSONS_PAGE, REMOTE_COACHES_PAGE } from 'constants/pages';
import { RemoteCoachTypesEnum } from 'utils/shared/coach/constants';
import ArrowRight from 'svg/ArrowRight';
import { ButtonText } from 'components/Button';
import CardsSection from 'components/CardSection';
import { CardSectionMode } from 'components/CardSection/props';
import NoDataPlaceholder from 'components/DiscoverPage/NoDataPlaceholder';
import Link from 'components/Link';
import LessonCard from '../LessonCard';
import { LessonBookingTabEnum, SelectCoachStepProps } from '../props';

const SelectCoachStep: React.FC<SelectCoachStepProps> = ({
  lessons,
  activeLesson,
  handleLessonSelect,
  loading,
  activeTab,
  setActiveTab,
}) => {
  const getNoDataPlaceholderText = () => {
    if (activeTab === LessonBookingTabEnum.OnCourt) {
      return (
        <span>
          There are no coaches nearby for in-person lessons yet. Try out a{' '}
          <ButtonText
            className="text-color-brand-primary"
            onClick={() => setActiveTab(LessonBookingTabEnum.Remote)}
          >
            Remote lesson
          </ButtonText>{' '}
          in the meantime
        </span>
      );
    } else {
      return (
        <span>
          There are no coaches available for remote lessons yet. Try out{' '}
          <ButtonText
            className="text-color-brand-primary"
            onClick={() => setActiveTab(LessonBookingTabEnum.OnCourt)}
          >
            On-Court lesson
          </ButtonText>{' '}
          in the meantime
        </span>
      );
    }
  };

  return (
    <div className="min-w-0 flex-1 max-xl:basis-[100%]">
      {!loading && lessons.length === 0 && <NoDataPlaceholder text={getNoDataPlaceholderText()} />}

      <CardsSection
        loading={loading}
        className="xl:h-[35rem]"
        gap="1rem"
        visibleCards={{ base: 1, md: 2, lg: 3, xl: 4 }}
        scrollOffset={{ base: 60 }}
        mode={{
          base: CardSectionMode.HORIZONTAL,
          xl: CardSectionMode.VERTICAL,
          xxl: CardSectionMode.VERTICAL,
        }}
        data={lessons}
        renderCard={(item, index) => (
          <LessonCard.Desktop
            key={index}
            isActive={activeLesson?.id === item.id}
            onClick={() => handleLessonSelect(item)}
            lesson={item}
            remoteCoachType={
              activeTab === LessonBookingTabEnum.OnCourt
                ? RemoteCoachTypesEnum.Court
                : RemoteCoachTypesEnum.Remote
            }
          />
        )}
      />
      <div className="mt-ds-2xl flex justify-center">
        <Link
          href={activeTab === LessonBookingTabEnum.Remote ? REMOTE_COACHES_PAGE : LESSONS_PAGE}
          className="typography-product-heading-compact flex cursor-pointer select-none items-center gap-1 text-color-brand-primary"
        >
          View all coaches <ArrowRight className="h-5 w-5" />
        </Link>
      </div>
    </div>
  );
};

export default SelectCoachStep;
