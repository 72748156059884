import { useState } from 'react';
import PaymentForm from 'screens/CoachDetails/LessonScheduler/PaymentForm';
import ResultForm from 'screens/CoachDetails/LessonScheduler/ResultForm';
import { Coach, Steps as ConfirmLessonSteps } from 'screens/CoachDetails/types';
import { ConfirmLessonStepProps } from 'screens/PlayerHome/props';

const ConfirmLessonStep: React.FC<ConfirmLessonStepProps> = ({
  activeLesson,
  submissionData,
  disableSubmit,
  clearForm,
}) => {
  const [steps, setSteps] = useState<ConfirmLessonSteps>(ConfirmLessonSteps.PaymentForm);
  const [bookedLessonId, setBookedLessonId] = useState<string | null>(null);

  const handleBookAnotherLesson = () => {
    setSteps(ConfirmLessonSteps.PaymentForm);
    clearForm();
  };

  return (
    <div className="min-w-0 flex-1">
      {steps === ConfirmLessonSteps.PaymentForm && (
        <PaymentForm
          setBookedLessonId={setBookedLessonId}
          setSteps={setSteps}
          coach={activeLesson as never as Coach}
          isRemoteCoaching={true}
          remoteCoachData={submissionData}
          disableSubmit={disableSubmit}
        />
      )}
      {steps === ConfirmLessonSteps.ResultForm && (
        <ResultForm
          bookedLessonId={bookedLessonId}
          setSteps={setSteps}
          coach={activeLesson as never as Coach}
          isRemoteCoaching={true}
          handleBookAnotherLesson={handleBookAnotherLesson}
        />
      )}
    </div>
  );
};

export default ConfirmLessonStep;
