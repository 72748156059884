import {
  COURT_FINDER_PAGE,
  LESSONS_PAGE,
  TOURNAMENTS_PAGE,
  TOURNAMENT_QUICK_CREATE_PAGE,
} from 'constants/pages';
import ArrowRight from 'svg/ArrowRight';
import { DiscoverImageType } from '../props';
import { getLeadCommunityMailto } from '../utils';

export const discoverImages: DiscoverImageType[] = [
  {
    path: '/images/players/discover-coach.png',
    title: 'Book on-court lesson',
    buttonProps: {
      title: 'Book Lesson',
      href: LESSONS_PAGE,
      icon: <ArrowRight className="h-5 w-5 max-sm:h-4 max-sm:w-4" />,
    },
  },
  {
    path: '/images/players/discover-tourn.png',
    title: 'Register for a tournament',
    buttonProps: {
      title: 'Register',
      href: TOURNAMENTS_PAGE,
      icon: <ArrowRight className="h-5 w-5 max-sm:h-4 max-sm:w-4" />,
    },
  },
  {
    path: '/images/players/discover-round-robin.png',
    title: 'Create a Round-robin',
    buttonProps: {
      title: 'Create',
      href: TOURNAMENT_QUICK_CREATE_PAGE,
      icon: <ArrowRight className="h-5 w-5 max-sm:h-4 max-sm:w-4" />,
    },
  },
  {
    path: '/images/players/discover-court.png',
    title: 'Find courts',
    buttonProps: {
      title: 'Search',
      href: COURT_FINDER_PAGE,
      icon: <ArrowRight className="h-5 w-5 max-sm:h-4 max-sm:w-4" />,
    },
  },
  {
    path: '/images/players/discover-group.png',
    title: 'Lead a community',
    buttonProps: {
      title: 'Start',
      href: getLeadCommunityMailto(),
      icon: <ArrowRight className="h-5 w-5 max-sm:h-4 max-sm:w-4" />,
    },
  },
];
