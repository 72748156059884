import styled, { css } from 'styled-components';
import { CardProps, CardSectionMode } from './props';
import { breakpoints, getCardSize, getResponsiveValue } from './utils';

const responsiveStyles = ({ mode, visibleCards, scrollOffset, gap }: CardProps) => css`
  width: ${getResponsiveValue(mode, 'base') === CardSectionMode.HORIZONTAL
    ? getCardSize(
        getResponsiveValue(visibleCards, 'base'),
        getResponsiveValue(scrollOffset, 'base'),
        '100%',
        gap,
      )
    : 'auto'};

  height: ${getResponsiveValue(mode, 'base') === CardSectionMode.VERTICAL
    ? getCardSize(
        getResponsiveValue(visibleCards, 'base'),
        getResponsiveValue(scrollOffset, 'base'),
        '100%',
        gap,
      )
    : 'auto'};
`;

const applyCardMediaQueries = (props: CardProps) =>
  (Object.keys(breakpoints) as Array<keyof typeof breakpoints>).map((bp) => {
    return css`
      @media ${breakpoints[bp]} {
        width: ${getResponsiveValue(props.mode, bp) === CardSectionMode.HORIZONTAL
          ? getCardSize(
              getResponsiveValue(props.visibleCards, bp),
              getResponsiveValue(props.scrollOffset, bp),
              '100%',
              props.gap,
            )
          : 'auto'};
        height: ${getResponsiveValue(props.mode, bp) === CardSectionMode.VERTICAL
          ? getCardSize(
              getResponsiveValue(props.visibleCards, bp),
              getResponsiveValue(props.scrollOffset, bp),
              '100%',
              props.gap,
            )
          : 'auto'};
      }
    `;
  });

// Media queries for Container (handles flex-direction and scroll-snap-type)
const applyContainerMediaQueries = (props: CardProps) =>
  (Object.keys(breakpoints) as Array<keyof typeof breakpoints>).map((bp) => {
    return css`
      @media ${breakpoints[bp]} {
        flex-direction: ${getResponsiveValue(props.mode, bp) === CardSectionMode.HORIZONTAL
          ? 'row'
          : 'column'};
        scroll-snap-type: ${getResponsiveValue(props.mode, bp) === CardSectionMode.HORIZONTAL
          ? 'x mandatory'
          : 'y mandatory'};
      }
    `;
  });

export const CardWrapper = styled.div<CardProps>`
  scroll-snap-align: start;
  flex-shrink: 0;

  ${responsiveStyles}; // Apply base responsive styles
  ${applyCardMediaQueries}; // Apply media queries for width/height
`;

export const Container = styled.div<CardProps>`
  display: flex;
  flex-direction: ${(props) =>
    getResponsiveValue(props.mode, 'base') === CardSectionMode.HORIZONTAL ? 'row' : 'column'};
  overflow: auto;
  scroll-snap-type: ${(props) =>
    getResponsiveValue(props.mode, 'base') === CardSectionMode.HORIZONTAL
      ? 'x mandatory'
      : 'y mandatory'};
  gap: ${(props) => props.gap};
  scrollbar-width: none;
  height: 100%;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  ${applyContainerMediaQueries}; // Apply media queries for flex-direction and scroll-snap-type
`;
